import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { getInspectAndPublishToolUrl } from "@pages/project-details/project-data-management/data-management-utils";
import { OPEN_PROJECT_TARGET_ATTRIBUTE } from "@utils/project-utils";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Project ID. */
  projectId: string;
  /** ID of the registration revision. */
  registrationId: string;
}

export function InspectAndPublishButton({ projectId, registrationId }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();

  return (
    <SphereTooltip title="Inspect and publish the registered scans.">
      <FaroTextButton
        size="small"
        dataTestId="sa-inspect-button"
        sx={{ marginLeft: "-9px" }}
        to={getInspectAndPublishToolUrl(projectId, registrationId)}
        target={OPEN_PROJECT_TARGET_ATTRIBUTE}
        onClick={() => {
          trackEvent({
            name: DataManagementEvents.inspect,
            props: {
              projectId,
              registrationId,
            },
          });
        }}
      >
        Inspect
      </FaroTextButton>
    </SphereTooltip>
  );
}

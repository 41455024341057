import { useMemo } from "react";
import { Box, Step, StepLabel, SvgIcon } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import { selectProjectLoadingState } from "@faro-lotv/project-source";
import { BackgroundTaskState, CaptureTreeEntityType } from "@faro-lotv/service-wires";
import ActiveSvg from "@assets/icons/new/thick-circle_24px.svg?react";
import NoDataIcon from "@assets/icons/new/no_data.svg?react";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { DataManagementTable } from "@pages/project-details/project-data-management/data-management-table";
import {
  StepState,
  WorkflowState,
  TableItem,
  StepperIndices,
  TableItemState,
} from "@pages/project-details/project-data-management/data-management-types";
import { AddDataButton } from "@pages/project-details/project-data-management/add-data-button";
import { InspectAndPublishButton } from "@pages/project-details/project-data-management/inspect-and-publish-button";
import { OpenViewerButton } from "@pages/project-details/project-data-management/open-viewer-button";
import { RegisterDataButton } from "@pages/project-details/project-data-management/register-data-button";
import { StepIcon } from "@pages/project-details/project-data-management/step-icon";
import {
  hasRegisterErrorSelector,
  isRegisteredSelector,
  isRegisteringSelector,
  registeredDataSelector,
} from "@pages/project-details/project-data-management/registered-data/registered-data-selectors";
import { publishedDataSelector } from "@pages/project-details/project-data-management/published-data/published-data-selectors";
import {
  isProcessedSelector,
  isProcessingSelector,
  uploadedDataSelector,
} from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-selectors";
import {
  fetchingStatusAllRegistrationRevisionsSelector,
  fetchingStatusCaptureTreeForMainRevisionSelector,
  hasFetchedAllRegistrationRevisionsSelector,
  hasFetchedCaptureTreeForMainRevisionSelector,
} from "@store/capture-tree/capture-tree-selectors";
import { useAppSelector } from "@store/store-helper";
import { FetchingStatus } from "@store/store-types";
import { hasInProgressUploadTasksSelector, uploadTasksSelector } from "@store/upload-tasks/upload-tasks-selector";
import { currentUserSelector } from "@store/user/user-selector";
import { sphereColors } from "@styles/common-colors";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { SphereTooltip } from "@components/common/sphere-tooltip";

/**
 * The main page for the Staging Area workflow, including the progress stepper and the scan table.
 * The code is unfortunately complex and "ugly" since the basic data elements weren't constructed to work
 * well with such a workflow and the user can also add additional data later, resetting the workflow while
 * older data still exists.
 */
export function DataManagementWorkflow(): JSX.Element {
  const currentUser = useAppSelector(currentUserSelector);

  // ##### Upload tasks ##### //

  const uploadTasks = useAppSelector(uploadTasksSelector);
  let isUploading = useAppSelector(hasInProgressUploadTasksSelector);

  // ##### Uploaded data ##### //

  const uploadedEntities = useAppSelector(uploadedDataSelector);
  const isProcessing = useAppSelector(isProcessingSelector);
  const isProcessed = useAppSelector(isProcessedSelector);
  // TODO: The uploadedData entities currently don't contain info about an occurred error.
  // Detection and handling of errors will be part of https://faro01.atlassian.net/browse/TF-1699
  const hasProcessError: boolean = false;

  let timeUpload = 0;
  for (const upload of uploadedEntities) {
    const time = new Date(upload.createdAt).getTime();
    if (timeUpload < time) {
      timeUpload = time;
    }
  }

  const fetchingStatusUploaded = useAppSelector(fetchingStatusCaptureTreeForMainRevisionSelector);
  const hasFetchedCaptureTree = useAppSelector(hasFetchedCaptureTreeForMainRevisionSelector);
  const fetchingStatusIElements = useAppSelector(selectProjectLoadingState);

  // ##### Registered data ##### //

  const registeredEntities = useAppSelector(registeredDataSelector);
  const registeredEntity = registeredEntities.length ? registeredEntities[0] : undefined;
  const isRegistering = useAppSelector(isRegisteringSelector);
  const hasRegisterError = useAppSelector(hasRegisterErrorSelector);
  const projectIdRegistered = registeredEntity ? registeredEntity.projectId : undefined;
  const registrationId = registeredEntity ? registeredEntity.id : undefined;
  const timeRegister = registeredEntity ? (new Date(registeredEntity.modifiedAt)).getTime() : 0;
  const isRegistered = useAppSelector(isRegisteredSelector) && timeUpload <= timeRegister;

  // fetchingStatusPrepared and hasFetchedAllRegistrationRevisions are also used for publishedData.
  const fetchingStatusRegistered = useAppSelector(fetchingStatusAllRegistrationRevisionsSelector);
  const hasFetchedAllRegistrationRevisions = useAppSelector(hasFetchedAllRegistrationRevisionsSelector);

  // ##### Published data ##### //

  const publishedEntities = useAppSelector(publishedDataSelector);
  const publishedEntity = publishedEntities.length ? publishedEntities[0] : undefined;
  // TODO: The act of publishing still needs to be implemented as part of the upcoming changes to the Staging Area:
  // https://faro01.atlassian.net/browse/TF-1693
  const isPublishing: boolean = false;
  const timePublish = useMemo(()=>{
    return publishedEntity ? new Date(publishedEntity.modifiedAt).getTime() : 0;
  },[publishedEntity]);
  const isPublished = !!publishedEntity && timeUpload <= timePublish && timeRegister <= timePublish;
  // Detection and handling of errors will be part of https://faro01.atlassian.net/browse/TF-1699
  const hasPublishError: boolean = false;
  const projectIdPublished = publishedEntity ? publishedEntity.projectId : undefined;

  // ##### Table items (Part 1) ##### //

  let hasUploadError: boolean = false;
  const tableItems: TableItem[] = [];

  // We don't want to show table entries for (finished) upload tasks for which there's already a member in the
  // uploaded data array.
  const uploadedFilenamesMap: { [key: string]: boolean } = {};
  for (const uploadedEntity of uploadedEntities) {
    uploadedFilenamesMap[uploadedEntity.name] = true;
  }

  for (const task of uploadTasks) {
    if (uploadedFilenamesMap[task.fileName]) {
      continue;
    }

    let itemState: TableItemState;
    switch (task.status) {
      case BackgroundTaskState.started:
        itemState = "uploading";
        break;
      case BackgroundTaskState.succeeded:
        // It takes a while until a finished upload task results in an added entity to uploadedEntities in which case
        // the uploadedFilenamesMap check above resolves to true.
        // We have to handle this by marking the upload as still in progress in this case.
        isUploading = true;
        // "processing" would be confusing to the user.
        itemState = "uploading";
        break;
      case BackgroundTaskState.aborted:
      case BackgroundTaskState.failed:
        itemState = "error";
        hasUploadError = true;
        break;
      case BackgroundTaskState.created:
      case BackgroundTaskState.scheduled:
      default:
        itemState = "start";
        break;
    }

    const item: TableItem = {
      id: task.id,
      name: task.fileName,
      createdAt: task.createdAt,
      // Since it's only possible to see the uploads from the current user then one can correctly assume that all
      // local uploads are from the current user.
      createdBy: currentUser?.id ?? "",
      deviceType: CaptureTreeEntityType.elsScan,
      state: itemState,
      progress: task.progress,
    };
    tableItems.push(item);
  }

  const isUploaded = !isUploading && 0 < uploadedEntities.length;

  // ##### Determine the state ##### //

  // Determine the overall state.
  // The order should support uploading additional scans after later states have already been reached.
  // So the progress must be set to e.g. uploading although there already is e.g. published data.
  let state: WorkflowState = "start";
  let iActiveStep = StepperIndices.upload;

  if (isUploading) {
    state = "uploading";
    iActiveStep = StepperIndices.upload;
  } else if (hasUploadError) {
    state = "uploadError";
    iActiveStep = StepperIndices.upload;
  } else if (isProcessing) {
    state = "processing";
    iActiveStep = StepperIndices.process;
  } else if (hasProcessError) {
    state = "processError";
    iActiveStep = StepperIndices.process;
  } else if (isRegistering) {
    state = "registering";
    iActiveStep = StepperIndices.register;
  } else if (hasRegisterError) {
    state = "registerError";
    iActiveStep = StepperIndices.register;
  } else if (isPublishing) {
    state = "publishing";
    iActiveStep = StepperIndices.publish;
  } else if (hasPublishError) {
    state = "publishError";
    iActiveStep = StepperIndices.publish;
  } else if (isPublished) {
    state = "published";
    iActiveStep = StepperIndices.publish;
  } else if (isRegistered) {
    state = "registered";
    iActiveStep = StepperIndices.publish;
  } else if (isProcessed) {
    state = "processed";
    iActiveStep = StepperIndices.register;
  } else if (isUploaded) {
    state = "uploaded";
    iActiveStep = StepperIndices.process;
  }

  // Determine the state of the stepper icons.
  let uploadStepState: StepState = "todo";
  if (StepperIndices.process <= iActiveStep) {
    uploadStepState = "done";
  } else if (state === "uploading") {
    uploadStepState = "active";
  } else if (state === "uploadError") {
    uploadStepState = "error";
  }

  let processStepState: StepState = "todo";
  if (StepperIndices.register <= iActiveStep) {
    processStepState = "done";
  } else if (state === "processing") {
    processStepState = "active";
  } else if (state === "processError") {
    processStepState = "error";
  }

  let registerStepState: StepState = "todo";
  if (StepperIndices.publish <= iActiveStep) {
    registerStepState = "done";
  } else if (state === "registering") {
    registerStepState = "active";
  } else if (state === "registerError") {
    registerStepState = "error";
  }

  let publishStepState: StepState = "todo";
  if (state === "published") {
    publishStepState = "done";
  } else if (state === "publishing") {
    publishStepState = "active";
  } else if (state === "publishError") {
    publishStepState = "error";
  }

  // ##### Table items (Part 2) ##### //

  // We need to know if state is "published" before being able to determine the table items based on uploadedEntities.
  for (const upload of uploadedEntities) {
    let itemState: TableItemState;
    if (upload.isProcessing) {
      itemState = "processing";
    } else if (state === "published") {
      itemState = "published";
    } else {
      itemState = "unpublished";
    }

    const item: TableItem = {
      id: upload.id,
      name: upload.name,
      createdAt: upload.createdAt,
      createdBy: upload.createdBy,
      deviceType: CaptureTreeEntityType.elsScan,
      state: itemState,
    };
    tableItems.push(item);
  }

  // ##### Empty page or error page ##### //

  const isFetchingForTheFirstTime = !hasFetchedCaptureTree &&
    !hasFetchedAllRegistrationRevisions &&
    (fetchingStatusUploaded === FetchingStatus.pending ||
     fetchingStatusRegistered === FetchingStatus.pending ||
     fetchingStatusIElements === "loading");

  const shouldShowEmptyPage = tableItems.length === 0 && !isFetchingForTheFirstTime;

  const hasFailedToFetchUploadedData = fetchingStatusUploaded === FetchingStatus.rejected ||
    fetchingStatusIElements === "failed";

  const hasFailedToFetchRegisteredData = fetchingStatusRegistered === FetchingStatus.rejected;

  const emptyPageTitle = hasFailedToFetchUploadedData || hasFailedToFetchRegisteredData ? "Error" : "No uploaded data";

  const emptyPageSubtitle = (hasFailedToFetchRegisteredData || hasFailedToFetchUploadedData) ?
    "Failed to fetch the uploaded data for this project! Please reload the page and try again." :
    "No uploaded data to show for this project.";

  const emptyPageIcon = hasFailedToFetchUploadedData || hasFailedToFetchRegisteredData ? FailedIcon : NoDataIcon;

  // ##### JSX element ##### //

  // The Step elements don't work correctly when defined in their own file and then used with e.g. <UploadStep ... />.
  // This is very likely a bug in MUI. At least the following things don't work:
  //  - An additional connector line before the first step is drawn.
  //  - The icon attribute is set to NaN instead of the step's number.

  // The <div> with height: "24px" is needed so that the link buttons are shown at their desired vertical place.
  // One could also set position: "absolute" in the FaroTextButton's sx, but that leads to a bug with the
  // SphereTooltip which gets then drawn in front of the button and prevents clicking it.

  const UploadStep = (
    <Step key="Upload" completed={uploadStepState === "done"}>
      <StepLabel StepIconComponent={StepIcon} error={uploadStepState === "error"}>
        <div style={{ height: "24px" }}>
          Upload
          <AddDataButton />
        </div>
      </StepLabel>
    </Step>
  );

  const ProcessStep = (
    <Step key="Process" completed={processStepState === "done"}>
      <StepLabel StepIconComponent={StepIcon} error={processStepState === "error"}>
        Process
      </StepLabel>
    </Step>
  );

  // See the comment in step-icon.tsx why we need custom code to display the correct icon.
  const RegisterStep = (
    <Step key="Register" completed={registerStepState === "done"}>
      <StepLabel StepIconComponent={StepIcon} error={registerStepState === "error"}>
        <div style={{ height: "24px" }}>
          Register
          { (registerStepState === "done" && !!projectIdRegistered && !!registrationId) &&
            <InspectAndPublishButton projectId={projectIdRegistered} registrationId={registrationId} />
          }
          { (state === "registering") &&
            <div style={{ position: "relative", left: "-32px", top: "-20px" }}>
              <SphereTooltip title="Registration of the scans is in process. Please wait.">
                <FaroButtonSpinner loadingTrackColor={sphereColors.gray100} size="24px" marginLeft="0px" />
              </SphereTooltip>
            </div>
          }
          { (state === "processed") &&
            <div style={{ position: "relative", left: "-32px", top: "-20px" }}>
              <SphereTooltip title="Registration of the scans can be started.">
                <SvgIcon inheritViewBox component={ActiveSvg} sx={{ color: sphereColors.blue500 }} />
              </SphereTooltip>
            </div>
          }
        </div>
      </StepLabel>
    </Step>
  );

  const PublishStep = (
    <Step key="Publish" completed={publishStepState === "done"}>
      <StepLabel StepIconComponent={StepIcon} error={publishStepState === "error"}>
        <div style={{ height: "24px" }}>
          Publish
          { (publishStepState === "done" && !!projectIdPublished) &&
            <OpenViewerButton projectId={projectIdPublished} />
          }
        </div>
      </StepLabel>
    </Step>
  );

  return (
    <div>
      <Box
        data-testid="sa-workflow"
        sx={{
          marginTop: "14px",
          border: `1px solid ${sphereColors.gray200}`,
          borderRadius: 0,
          display: "inline-block",
          width: "100%",
        }}
      >
        {shouldShowEmptyPage ? (
          <EmptyPage title={emptyPageTitle} subtitle={emptyPageSubtitle} icon={emptyPageIcon} />
        ) : (
          <Box sx={{ background: "white" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: `1px solid ${sphereColors.gray200}`,
                paddingX: "30px",
                paddingTop: "30px",
                paddingBottom: "45px",
                fontWeight: "bold",
              }}
            >
              <div style={{ width: "100%" }}>
                <span style={{ marginRight: "100px", fontSize: "larger", float: "left" }}>
                  Blink scans
                 </span>

                <Stepper activeStep={iActiveStep}>
                  { UploadStep }
                  { ProcessStep }
                  { RegisterStep }
                  { PublishStep }
                </Stepper>
              </div>
            </Box>
            { state === "processed" &&
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: `1px solid ${sphereColors.gray200}`,
                  paddingX: "30px",
                  fontWeight: "bold",
                  height: "64px",
                }}
              >
                <RegisterDataButton />
              </Box>
            }
            <Box sx={{ paddingX: "30px", paddingBottom: "30px" }}>
              <DataManagementTable tableItems={tableItems} isFetchingForTheFirstTime={isFetchingForTheFirstTime} />
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}

import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { OpenProjectTarget } from "@custom-types/sdb-company-types";
import { getSphereViewerUrl, OPEN_OTHERS_TARGET_ATTRIBUTE, trackOpenProject } from "@utils/project-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Project ID. */
  projectId: string;
}

export function OpenViewerButton({ projectId }: Props): JSX.Element {
  const { trackAsyncEvent } = useTrackEvent();

  const sphereViewerUrl = getSphereViewerUrl({ projectId }).href;

  return (
    <SphereTooltip title="View the published data.">
      <FaroTextButton
        size="small"
        dataTestId="sa-open-button"
        sx={{ marginLeft: "-15px" }}
        to={sphereViewerUrl}
        target={OPEN_OTHERS_TARGET_ATTRIBUTE}
        onClick={() => {
          trackOpenProject({
            openFrom: "projectDetails-dataManagement",
            numberOfMembers: undefined,
            openTarget: OpenProjectTarget.sphereViewer,
            trackAsyncEvent,
          });
        }}
      >
        Open
      </FaroTextButton>
    </SphereTooltip>
  );
}

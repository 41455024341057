import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { Alert } from "@faro-lotv/flat-ui";
import { Grid, Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { FILE_SIZE_MULTIPLIER, sortFiles } from "@utils/file-utils";
import { useCallback, useMemo, useState } from "react";
import UploadSvg from "@assets/icons/new/upload_50px.svg?react";
import {
  ElsScanFileUploadTaskContext,
  MultiUploadedFileResponse,
  UploadedFile,
  UploadElementType,
  UploadFailedFile,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  selectedProjectIdSelector,
  selectedProjectSelector,
} from "@store/projects/projects-selector";
import { setIsImportDialogOpen } from "@store/ui/ui-slice";
import { isImportDialogOpenSelector } from "@store/ui/ui-selector";
import { ScanDataFile } from "@pages/project-details/project-data-management/import-data/scan-data-file";
import { ImportDataButton } from "@pages/project-details/project-data-management/import-data/import-data-button";
import { isValidFile } from "@hooks/file-upload-utils";
import { createRevisionForElsScans } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { getProjectApiClient } from "@api/project-api/project-api-utils";
import { getCreateScanEntitiesParams } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { RegistrationState } from "@faro-lotv/service-wires";
import { useFileUpload } from "@hooks/use-file-upload";
import { useToast } from "@hooks/use-toast";
import { FailedUploadsToastContent } from "@pages/project-details/project-data-management/import-data/failed-uploads-toast-content";
import { GUID } from "@faro-lotv/foundation";
import { ProjectApi } from "@api/project-api/project-api";

/** Maximum each file size of scan data */
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MAX_FILE_SIZE_IN_MB = 20 * FILE_SIZE_MULTIPLIER;

/** List of allowed extensions for importing scan data */
const ALLOWED_EXTENSIONS = ["gls"];

/** Renders a button and the dialog to import ELS scan data */
export function ImportData(): JSX.Element {
  const dispatch = useAppDispatch();
  const isDialogOpen = useAppSelector(isImportDialogOpenSelector);
  const projectId = useAppSelector(selectedProjectIdSelector);
  const project = useAppSelector(selectedProjectSelector);
  const { handleErrorWithToast, handleErrorSilently } = useErrorContext();
  const { uploadMultipleFiles, validateAndAddFailedTask } = useFileUpload();
  const { showToast } = useToast();

  const [files, setFiles] = useState<File[]>([]);
  const [isCreatingRevision, setIsCreatingRevision] = useState<boolean>(false);

  const isConfirmDisabled = useMemo(() => {
    if (!files.length) {
      return true;
    }

    // Check if there is any invalid files
    const hasInvalidFile = files.some((file) => {
      const validatedFile = isValidFile({
        file,
        allowedExtensions: ALLOWED_EXTENSIONS,
        maxFileSize: MAX_FILE_SIZE_IN_MB,
      });

      return !validatedFile.isValid;
    });

    return hasInvalidFile;
  }, [files]);

  /** Sets revision as canceled */
  const cancelRevision = useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      try {
        await projectApiClient.updateRegistrationRevision({
          registrationRevisionId,
          state: RegistrationState.canceled,
        });
      } catch (error) {
        // If it fails to set the revision as canceled only log the error but
        // don't show it to the user since this is not a critical step.
        handleErrorSilently({
          id: `updateRegistrationRevision-${Date.now().toString()}`,
          title: "Failed to set revision as canceled.",
          error,
        });
      }
    },
    [handleErrorSilently]
  );

  /** Handles the case when all uploads were canceled by the user */
  const handleAllUploadsCanceled = useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      showToast({
        message: "Cancelled all Blink scan imports.",
        type: "info",
        shouldAutoHide: true,
      });

      await cancelRevision(projectApiClient, registrationRevisionId);
    },
    [cancelRevision, showToast]
  );

  /** Handles the case when all uploads failed */
  const handleAllUploadsFailed = useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      showToast({
        message:
          "All Blink scan imports failed. Please try to upload them again.",
        type: "error",
      });

      await cancelRevision(projectApiClient, registrationRevisionId);
    },
    [cancelRevision, showToast]
  );

  /**
   * Handles the logic after the upload of ELS scan succeeded:
   * - Adds (creates) successful uploads to the specified cluster of the project revision.
   * - Updates revision to "registered" status and merges it to the main revision.
   * - If it fails it will then set the revision as canceled.
   */
  const addScansToRevisionAndMergeToMain = useCallback(
    async (
      successfulUploads: UploadedFile[],
      failedUploads: UploadFailedFile[],
      projectApiClient: ProjectApi,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      try {
        const scanEntitiesParams = getCreateScanEntitiesParams({
          uploadedScans: successfulUploads,
          revisionClusterEntityId: context.revisionClusterEntityId,
        });

        await projectApiClient.createScanEntitiesForRegistrationRevision({
          registrationRevisionId: context.registrationRevisionId,
          requestBody: scanEntitiesParams,
        });

        await projectApiClient.updateRegistrationRevision({
          registrationRevisionId: context.registrationRevisionId,
          state: RegistrationState.registered,
        });

        await projectApiClient.applyRegistrationRevisionToMain(
          context.registrationRevisionId
        );

        // Show a warning toast with the list of files that failed to upload
        if (failedUploads.length) {
          showToast({
            message: "Data import was partially successful",
            type: "warning",
            shouldAutoHide: false,
            description: (
              <FailedUploadsToastContent
                failedUploads={failedUploads}
                projectName={project?.name}
              />
            ),
          });
        }
      } catch (error) {
        handleErrorWithToast({
          id: `addScansToRevisionAndMergeToMain-${Date.now().toString()}`,
          title:
            "Failed to add imported data to project. Please try to import data again.",
          error,
        });

        // Attempt to set the revision as canceled
        await cancelRevision(projectApiClient, context.registrationRevisionId);
      }
    },
    [cancelRevision, handleErrorWithToast, project?.name, showToast]
  );

  const onUploadComplete = useCallback(
    async (
      uploadedResponse: MultiUploadedFileResponse,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      const projectApiClient = getProjectApiClient({
        projectId: context.projectId,
      });

      const successfulUploads = uploadedResponse.successful;
      const failedUploads = uploadedResponse.failed;
      const canceledUploads = uploadedResponse.canceled;

      // Handle case when there are zero successful uploads
      if (!successfulUploads.length) {
        // If there are failed uploads consider it an all-failed case
        if (failedUploads.length) {
          return handleAllUploadsFailed(
            projectApiClient,
            context.registrationRevisionId
          );
        }

        // If there are zero failed uploads and some cancelled uploads consider it an all-cancelled case
        if (!failedUploads.length && canceledUploads.length) {
          return handleAllUploadsCanceled(
            projectApiClient,
            context.registrationRevisionId
          );
        }
      }

      // Handle case when there were successful uploads
      if (successfulUploads.length) {
        return addScansToRevisionAndMergeToMain(
          successfulUploads,
          failedUploads,
          projectApiClient,
          context
        );
      }
    },
    [
      addScansToRevisionAndMergeToMain,
      handleAllUploadsCanceled,
      handleAllUploadsFailed,
    ]
  );

  const initiateMultipleFileUpload = useCallback(
    async (
      registrationRevisionId: string,
      revisionClusterEntityId: string
    ): Promise<void> => {
      // Early return if project ID is not provided
      if (!projectId) {
        return;
      }

      const context: ElsScanFileUploadTaskContext = {
        uploadElementType: UploadElementType.elsScan,
        projectId,
        registrationRevisionId,
        revisionClusterEntityId,
      };

      const uploadableFiles = files.filter((file) =>
        validateAndAddFailedTask({
          file,
          allowedExtensions: ALLOWED_EXTENSIONS,
          maxFileSize: MAX_FILE_SIZE_IN_MB,
          context,
        })
      );

      // Return if there is no uploadable file
      if (!uploadableFiles.length) {
        return;
      }

      const uploadParams: UploadMultipleFilesParams = {
        files: uploadableFiles,
        onUploadStart: () => undefined,
        onUploadProgress: () => undefined,
        onUploadComplete,
        context,
      };

      await uploadMultipleFiles(uploadParams);
    },
    [
      files,
      onUploadComplete,
      projectId,
      uploadMultipleFiles,
      validateAndAddFailedTask,
    ]
  );

  const onConfirm = useCallback(async (): Promise<void> => {
    setIsCreatingRevision(true);

    try {
      const { registrationRevisionId, revisionClusterEntityId } =
        await createRevisionForElsScans(projectId);

      initiateMultipleFileUpload(
        registrationRevisionId,
        revisionClusterEntityId
      );

      dispatch(setIsImportDialogOpen(false));
      setFiles([]);
    } catch (error) {
      handleErrorWithToast({
        id: `createRevisionForElsScans-${Date.now().toString()}`,
        title: "Failed to prepare a revision to import data. Please try again.",
        error,
      });
    }

    setIsCreatingRevision(false);
  }, [handleErrorWithToast, initiateMultipleFileUpload, projectId, dispatch]);

  function onSelectFiles(
    selectedFiles: FileList | File[],
    _: () => void
  ): void {
    const combinedFiles = [...files, ...selectedFiles];
    setFiles(sortFiles(combinedFiles));
  }

  function closeDialog(): void {
    dispatch(setIsImportDialogOpen(false));
    setFiles([]);
  }

  function removeFile(index: number): void {
    const allFiles = [...files];
    allFiles.splice(index, 1);

    setFiles(sortFiles(allFiles));
  }

  return (
    <>
      <ImportDataButton onClick={() => dispatch(setIsImportDialogOpen(true))} />
      <FaroDialog
        title="Upload data"
        confirmText="Confirm"
        open={isDialogOpen}
        onConfirm={onConfirm}
        isConfirmDisabled={isConfirmDisabled}
        isConfirmLoading={isCreatingRevision}
        onClose={closeDialog}
      >
        <Grid maxWidth="100%" width="70vw">
          <Alert
            title='For this Beta version you can only upload a folder with raw Blink data (.gls).
              Please upload the folder which contains "index-v2".'
            variant="info"
            sx={{
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
              backgroundColor: sphereColors.blue100,
              color: sphereColors.black,
            }}
          />
          <Stack>
            <SphereDropzone
              instruction="Drag & drop"
              maxFileSize={MAX_FILE_SIZE_IN_MB}
              shouldShowSupportedFormats={false}
              shouldShowSizeLimit={false}
              shouldAllowMultiUpload={true}
              shouldAllowFolderUpload={true}
              shouldAllowFiles={false}
              avatar={
                <SphereAvatar
                  icon={<UploadSvg />}
                  size="x-large"
                  shouldHideWhiteRim
                  iconColor={sphereColors.gray600}
                  backgroundColor={sphereColors.gray100}
                />
              }
              allowedExtensions={ALLOWED_EXTENSIONS}
              isLoading={false}
              setIsLoading={() => undefined}
              onUploadComplete={onUploadComplete}
              context={{
                uploadElementType: UploadElementType.elsScan,
                projectId: projectId ?? "",
                registrationRevisionId: "",
                revisionClusterEntityId: "",
              }}
              onSelectFiles={onSelectFiles}
            />
          </Stack>

          {files.length !== 0 && (
            <Stack
              sx={{
                my: SPACE_ELEMENTS_OF_MODAL,
                maxHeight: "200px",
                overflow: "auto",
              }}
            >
              {files.map((file, index) => (
                <ScanDataFile
                  key={index}
                  fileName={file.name}
                  fileSize={file.size}
                  onDelete={() => removeFile(index)}
                  isValid={
                    isValidFile({
                      file,
                      allowedExtensions: ALLOWED_EXTENSIONS,
                      maxFileSize: MAX_FILE_SIZE_IN_MB,
                    }).isValid
                  }
                />
              ))}
            </Stack>
          )}
        </Grid>
      </FaroDialog>
    </>
  );
}

import { StepIconProps, SvgIcon } from "@mui/material";
import ActiveSvg from "@assets/icons/new/thick-circle_24px.svg?react";
import CompletedSvg from "@assets/icons/new/checkmark-circle-fill_24px.svg?react";
import TodoSvg from "@assets/icons/new/gray-circle_24px.svg?react";
import ErrorSvg from "@assets/icons/new/exclamation-mark-circle-fill_24px.svg?react";
import { sphereColors } from "@styles/common-colors";
import { StepperIndices } from "@pages/project-details/project-data-management/data-management-types";
import { SphereTooltip } from "@components/common/sphere-tooltip";

// eslint-disable-next-line @typescript-eslint/naming-convention -- names given by package
export function StepIcon({ active, completed, error, icon }: StepIconProps): JSX.Element {
  // We can identify the Register step icon by its default label, the number 3, so that we can hide the default
  // circle icon for an active step and let RegisterStep handle the display of the correct icon:
  //  * Thick circle while waiting for registration to begin.
  //  * Spinner while registration is in process.
  // In this function, we don't have the necessary info to do this since we can't provide custom properties to it.
  if (completed) {
    return (
      <SphereTooltip title="The step has been finished successfully!" boxProps={{ display: "flex" }}>
        <SvgIcon inheritViewBox component={CompletedSvg} sx={{ color: sphereColors.blue500 }} />
      </SphereTooltip>
    );
  } else if (error) {
    return (
      <SphereTooltip title="An error has occurred!" boxProps={{ display: "flex" }}>
        <SvgIcon inheritViewBox component={ErrorSvg} sx={{ color: sphereColors.red500 }} />
      </SphereTooltip>
    );
  } else if (active) {
    return (
      <SphereTooltip title="The step is currently getting carried out. Please wait." boxProps={{ display: "flex" }}>
        <SvgIcon
          inheritViewBox
          component={ActiveSvg}
          sx={{
            color: sphereColors.blue500,
            opacity: (icon === StepperIndices.register + 1) ? 0 : 100,
        }} />
      </SphereTooltip>
    );
  } else {
    return (
      <SphereTooltip title="The step is not yet ready to be carried out." boxProps={{ display: "flex" }}>
        <SvgIcon inheritViewBox component={TodoSvg} />
      </SphereTooltip>
    );
  }
}

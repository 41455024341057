import { FaroTextButton } from "@components/common/faro-text-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { useAppDispatch } from "@store/store-helper";
import { setIsImportDialogOpen } from "@store/ui/ui-slice";

export function AddDataButton(): JSX.Element {
  const dispatch = useAppDispatch();

  return (
    <SphereTooltip title="Upload additional data.">
      <FaroTextButton
        size="small"
        dataTestId="sa-add-data-button"
        sx={{ marginLeft: "-4px" }}
        onClick={() => dispatch(setIsImportDialogOpen(true))}
      >
        Add Data
      </FaroTextButton>
    </SphereTooltip>
  );
}
